var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign-in"},[_c('a-row',{attrs:{"type":"flex","gutter":[24,24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{span: 12, offset: 0},"xl":{span: 8, offset: 2}}},[_c('h2',{staticClass:"mb-15"},[_vm._v("Sign Up To Domos")]),_c('h5',{staticClass:"font-regular text-muted"},[_vm._v("Use your Google account to create an account")]),_c('div',{staticClass:"sign-in-gateways"},[_c('a-button',{on:{"click":function($event){return _vm.handleSignUp('gmail')}}},[_c('span',{staticClass:"text"},[_vm._v("Continue with Google")]),_c('img',{attrs:{"src":"images/logos/Google__G__Logo.svg.png","alt":"Continue with Google"}})])],1),_c('h5',{staticClass:"font-regular text-muted"},[_vm._v("Or enter your email and password to sign up")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Full Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'name',
					{ rules: [{ required: true, message: 'Please input your name!' }] },
					]),expression:"[\n\t\t\t\t\t'name',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your name!' }] },\n\t\t\t\t\t]"}],ref:"name",attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Email","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'email',
					{ rules: [{ required: true, message: 'Please input your email!' }] },
					]),expression:"[\n\t\t\t\t\t'email',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your email!' }] },\n\t\t\t\t\t]"}],ref:"email",attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Choose Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'password',
					{ rules: [{ required: true, message: 'Please input your Password!' }] },
					]),expression:"[\n\t\t\t\t\t'password',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your Password!' }] },\n\t\t\t\t\t]"}],ref:"password",attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'remember',
					{
						valuePropName: 'checked',
						initialValue: true,
					},
					]),expression:"[\n\t\t\t\t\t'remember',\n\t\t\t\t\t{\n\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t},\n\t\t\t\t\t]"}]},[_vm._v(" I agree the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted"},[_vm._v("Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Sign In")])],1),_c('p',{staticClass:"font-semibold text-muted"},[_vm._v("Need Support? "),_c('a',{attrs:{"href":"mailto:support@joindomos.com?subject=Sign Up Support","target":"_blank"}},[_vm._v("Email Us")])])],1),_c('a-col',{staticClass:"col-img",attrs:{"span":24,"md":12,"lg":12,"xl":6}},[_c('img',{attrs:{"src":"images/puppies.png","alt":""}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }